<template>
  <v-progress-linear v-if="loading" indeterminate/>
  <v-container v-else>
    <v-row justify="center">
      <v-col lg="9">
        <h2>Adviser profile</h2>
        <v-form class="adviser-form">
          <v-text-field
            label="User name"
            disabled
            v-model="formData.auth.displayName"
            data-cy="input-username"
          />
          <v-text-field
            label="Email"
            disabled
            v-model="formData.auth.email"
            data-cy="input-email"
          />
          <v-text-field
            label="Phone"
            disabled
            v-model="formData.auth.phone"
            data-cy="input-phone"
          />
          <v-text-field
            label="Full name"
            v-model="formData.profile.fullName"
            data-cy="input-fullname"
          />
          <v-text-field
            label="Registration"
            v-model="formData.profile.registration"
            data-cy="input-registration"
          />
          <v-text-field
            label="Business Name"
            v-model="formData.profile.businessName"
            data-cy="input-business-name"
          />
          <v-text-field
            label="Business Registration"
            v-model="formData.profile.businessRegistration"
            data-cy="input-business-registration"
          />
          <v-text-field
            label="Business Email"
            v-model="formData.profile.businessEmail"
            data-cy="input-business-email"
          />
          <v-file-input
            class="files-input"
            show-size
            chips
            :disabled="fileInProcess"
            :loading="fileInProcess"
            label="Loan Profile"
            v-model="loanProfileFile.file"
          />
          <v-file-input
            class="files-input"
            show-size
            chips
            :disabled="fileInProcess"
            :loading="fileInProcess"
            label="Marketing book / Disclosure statement"
            v-model="bookStatementFile.file"
          />
          <v-file-input
            class="files-input"
            show-size
            chips
            :disabled="fileInProcess"
            :loading="fileInProcess"
            label="Authority and declaration"
            v-model="declarationFile.file"
          />
          <v-file-input
            class="files-input"
            show-size
            chips
            :disabled="fileInProcess"
            :loading="fileInProcess"
            label="Claw back form"
            v-model="clawFile.file"
          />
          <v-card
            class="my-2"
            elevation="0"
            v-show="isUploadedFilesExist"
            outlined
            tile
          >
            <v-card-title>
              Files to upload
            </v-card-title>

            <v-card-text>
              <v-simple-table>
                <template #default>
                  <thead>
                  <tr>
                    <th class="text-left">
                      File Name
                    </th>
                    <th class="text-left">
                      File Description
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                    v-for="(file, fileIdx) in uploadedFiles"
                    :key="fileIdx"
                  >
                    <td>{{ file.file.name }}</td>
                    <td>
                      <v-text-field
                        class="file-description"
                        type="text"
                        disabled
                        v-model="file.description"
                      ></v-text-field>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>

            <v-card-actions>
              <v-btn
                v-if="!fileInProcess"
                color="primary"
                @click.prevent="uploadFiles"
              >
                Upload
              </v-btn>
            </v-card-actions>
          </v-card>
          <div class="files">
            <v-subheader>YOUR FILES</v-subheader>
            <div class="files-table">
              <div class="files-table__header">
                <span class="files-table__label text-center col-3">File Name</span>
                <span class="files-table__label text-center col-3">File Description</span>
                <span class="files-table__label text-center col-2">Size</span>
                <span class="files-table__label text-center col-2">Created At</span>
                <span class="files-table__label text-center col-1"></span>
                <span class="files-table__label text-center col-1"></span>
              </div>

              <div class="files-table__content">
                <Draggable
                  v-model="storedFilesDraggable"
                  @start="isDragging = true"
                  @end="isDragging = false"
                  :move="changeFileOrder"
                  v-bind="dragOptions"
                >
                  <div
                    v-for="(file, fileIdx) in sortedStoredFiles"
                    :key="fileIdx"
                    class="files-table__item"
                  >
                    <div class="col-3">{{ file.filename }}</div>
                    <div class="col-3">
                      <v-text-field
                        class="without-indents file-description"
                        disabled
                        v-model="file.description"
                        @blur="fileDescriptionEdit(file)"
                        type="text"
                      ></v-text-field>
                    </div>
                    <div class="col-2">{{ file.size | formatBytes }}</div>
                    <div class="col-2">{{ file.timeCreated }}</div>
                    <div class="col-1">
                      <v-btn
                        :disabled="fileInProcess"
                        @click.prevent="download(file)"
                      >
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                    </div>
                    <div class="col-1">
                      <v-btn
                        icon
                        :disabled="fileInProcess"
                        @click.prevent="moveToArchive(file)"
                      >
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </Draggable>
              </div>
            </div>
          </div>
          <v-btn
            color="primary"
            :loading="saving"
            @click="clickSave"
            data-cy="btn-save"
          >SAVE
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Draggable from 'vuedraggable';

import {
  getUserInfo,
  getCurrentUser,
  updateUserInfo,
  FILE_STATUS,
  uploadToAdviserFile as uploadFileToStorage,
  downloadFile,
  saveOrderFile,
  getAdviserFileByAdviserId,
  archiveFile,
} from '@/services/firebase';

export default {
  name: 'AdviserProfile',

  components: {
    Draggable,
  },

  data() {
    return {
      userId: null,
      saving: false,
      formData: {},
      loading: true,
      storedFiles: [],
      fileInProcess: false,
      filesCount: 0,
      loanProfileFile: {
        file: null,
        description: 'Loan Profile',
      },
      bookStatementFile: {
        file: null,
        description: 'Marketing book / disclosure statement',
      },
      declarationFile: {
        file: null,
        description: 'Authority and declaration',
      },
      clawFile: {
        file: null,
        description: 'Claw back form',
      },
    };
  },

  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },

    storedFilesDraggable: {
      get() {
        return this.sortedStoredFiles;
      },

      set(sortedFiles) {
        const saveDelay = 1000;

        const setNewOrderInFile = (file, idx) => {
          const orderFile = { ...file };
          orderFile.order = idx;

          return orderFile;
        };

        const newSortedFiles = sortedFiles.map(setNewOrderInFile);

        this.storedFiles = [...newSortedFiles];

        this.needSaveFilesOrder = true;

        setTimeout(this.saveFilesOrder(this.storedFiles), saveDelay);
      },
    },

    sortedStoredFiles() {
      const files = this.sortStoredFiles();

      return files;
    },

    isLoanProfileFileExist() {
      const isUploadedFileExist = Boolean(this.loanProfileFile.file);

      return isUploadedFileExist;
    },

    isBookStatementFileExist() {
      const isUploadedFileExist = Boolean(this.bookStatementFile.file);

      return isUploadedFileExist;
    },

    isdeclarationFileExist() {
      const isUploadedFileExist = Boolean(this.declarationFile.file);

      return isUploadedFileExist;
    },

    isClawFileExist() {
      const isUploadedFileExist = Boolean(this.clawFile.file);

      return isUploadedFileExist;
    },

    uploadedFiles() {
      const files = [];
      if (this.isLoanProfileFileExist) {
        files.push(this.loanProfileFile);
      }
      if (this.isBookStatementFileExist) {
        files.push(this.bookStatementFile);
      }
      if (this.isdeclarationFileExist) {
        files.push(this.declarationFile);
      }
      if (this.isClawFileExist) {
        files.push(this.clawFile);
      }
      return files;
    },

    isUploadedFilesExist() {
      const isUploadedFilesExist = Boolean(this.uploadedFiles?.length > 0);

      return isUploadedFilesExist;
    },
  },

  async mounted() {
    this.userId = getCurrentUser().uid;

    await this.getFiles();
    this.clearUploadedFiles();

    getUserInfo(this.userId)
      .then((response) => {
        this.formData = response.data();
        this.loading = false;
      });
  },

  methods: {
    clickSave() {
      updateUserInfo(getCurrentUser().uid, this.formData)
        .finally(() => {
          this.saving = false;
        });
    },

    saveFilesOrder(files) {
      const isNotNeedSaveFilesOrder = Boolean(!this.needSaveFilesOrder);
      if (isNotNeedSaveFilesOrder) {
        return;
      }

      files.forEach((item) => {
        saveOrderFile(item);
      });
    },

    sortStoredFiles() {
      const sortFiles = (firstFile, secondFile) => firstFile.order - secondFile.order;

      const files = [...this.storedFiles];
      files.sort(sortFiles);

      return files;
    },

    async uploadFiles() {
      this.fileInProcess = true;

      const uploadFile = async (file, idx) => {
        const fileData = file.file;

        const fileMetadata = {
          customMetadata: {
            filename: fileData.name,
            description: file.description ?? '',
            status: FILE_STATUS.active,
            order: idx + this.filesCount,
          },
        };

        const filePayload = {
          file: fileData,
          metadata: fileMetadata,
          adviserId: this.userId,
        };

        const fileResponse = await uploadFileToStorage(filePayload);

        this.setFileToStoredFiles(fileResponse.metadata);
      };

      const stored = this.sortedStoredFiles;

      if (stored.length) {
        for (let i = 0; i < this.uploadedFiles.length; i += 1) {
          const uploadedFileDescription = this.uploadedFiles[i].description;
          const remove = stored.filter((item) => item.description === uploadedFileDescription);
          if (remove.length) {
            await this.moveToArchive(remove[0]);
          }
        }
      }

      for (let i = 0; i < this.uploadedFiles.length; i += 1) {
        await uploadFile(this.uploadedFiles[i], i);
      }

      this.clearUploadedFiles();

      this.fileInProcess = false;
    },

    removeFromStoredFiles(removedFile) {
      const findFileByPath = (file) => file.path === removedFile.path;

      const fileIndex = this.storedFiles.findIndex(findFileByPath);

      this.storedFiles.splice(fileIndex, 1);
    },

    async getFiles() {
      const files = await getAdviserFileByAdviserId(this.userId);

      const setStoredFile = (file) => {
        file.getMetadata().then(this.setFileToStoredFiles);
      };

      this.storedFiles = [];
      files.items.forEach(setStoredFile);
    },

    clearUploadedFiles() {
      this.loanProfileFile.file = null;
      this.bookStatementFile.file = null;
      this.declarationFile.file = null;
      this.clawFile.file = null;
    },

    async moveToArchive(file) {
      this.fileInProcess = true;
      await archiveFile(file);

      this.removeFromStoredFiles(file);
      this.fileInProcess = false;
    },

    async download(file) {
      const fileDownload = downloadFile(file.path);
      const url = await fileDownload.getDownloadURL();

      const downloadLink = document.createElement('a');
      downloadLink.setAttribute('href', url);
      downloadLink.setAttribute('target', '_blank');
      downloadLink.setAttribute('download', file.filename);

      downloadLink.style.display = 'none';
      document.body.appendChild(downloadLink);

      downloadLink.click();
      document.body.removeChild(downloadLink);
    },

    changeFileOrder({ relatedContext, draggedContext }) {
      this.needSaveFilesOrder = false;

      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;

      const isNotRelatedElement = Boolean(!relatedElement || !relatedElement.fixed);
      const isFileOrderChanged = Boolean(isNotRelatedElement && !draggedElement.fixed);

      return isFileOrderChanged;
    },

    setFileToStoredFiles(fileMetadata) {
      const fileName = fileMetadata?.customMetadata?.filename ?? fileMetadata?.filename;
      const isFileNotActive = Boolean(fileMetadata?.customMetadata?.status !== FILE_STATUS.active);

      if (isFileNotActive) {
        return;
      }

      const fileData = {
        path: fileMetadata.fullPath,
        size: fileMetadata.size,
        contentType: fileMetadata.contentType,
        timeCreated: fileMetadata.timeCreated,
        filename: fileName,
        description: fileMetadata?.customMetadata?.description ?? '-',
        order: fileMetadata?.customMetadata?.order,
      };

      this.storedFiles.push(fileData);
    },
  },
};
</script>

<style lang="scss">
.adviser-form {
  margin-bottom: 22px;
}

.without-indents {
  margin: 0;
  padding: 0;
}
.files {
  display: flex;
  flex-direction: column;

  &-table {
    display: flex;
    flex-direction: column;
    padding: 0 16px;

    &__header {
      display: flex;
      justify-content: space-between;
    }

    &__item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px 0;
    }
  }
}
</style>
